<template>
  <b-overlay :show="isLoading" rounded="lg">
    <b-card title="Add New Bundle">
      <b-col cols="12">
        <b-row>
          <b-col cols="12">
            <b-form-group>
              <span>Bundle Name <span style="color:red">(*)</span></span>
              <b-form-input
                v-model="name"
                type="text"
                placeholder="Enter Bundle Name"
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group>
              <span>Distribution Channel</span>
              <b-form-select v-model="selectChannel" @change="(value) => onSelectChannel()">
                <option
                  v-for="(selectOption, indexOpt) in listChannel"
                  :key="indexOpt"
                  :value="selectOption"
                >
                  {{ selectOption.name }}
                </option>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="4">
            <b-form-group>
              <span>Item Model <span style="color:red">(*)</span></span>
              <b-form-select
                v-model="selectItem"
                :options="itemChannel"
                :disabled="itemChannel.length === 0"
              >
              </b-form-select>
              <strong v-if="itemChannel.length === 0" style="color:red;"
                >Please Select Distribution Channel first</strong
              >
            </b-form-group>
          </b-col>
          <b-col cols="12" md="4">
            <b-form-group>
              <span>Quantity <span style="color:red">(*)</span></span>
              <b-form-input
                type="number"
                placeholder="Quantity"
                required
                v-model="selectQuantity"
                :disabled="itemChannel.length === 0"
              >
              </b-form-input>
              <strong v-if="itemChannel.length === 0" style="color:red;"
                >Please Select Distribution Channel first</strong
              >
            </b-form-group>
          </b-col>
          <b-col cols="12" md="4">
            <b-button
              @click="addItemBundle"
              variant="outline-success"
              :disabled="!selectItem || Object.keys(selectItem).length === 0"
              style="margin-top:25px !important;"
            >
              Add Item
            </b-button>
          </b-col>

          <b-col cols="12" class="mt-2 table-responsive" >
            <strong v-if="items.length === 0" style="color:red;"
              >Item List Required. Please Add Item Model first</strong
            >
            <b-table :items="items" :fields="fields" show-empty>
              <template #cell(actions)="row">
                <b-button
                  size="sm"
                  @click="removeItem(row)"
                  class="mr-1"
                  variant="danger"
                >
                  <feather-icon icon="MinusIcon" />
                </b-button>
              </template>
            </b-table>
          </b-col>

          <b-col cols="12">
            <b-button @click="doSaveBundle" variant="success" :disabled="items.length === 0">
              Save Bundle
            </b-button>
          </b-col>
        </b-row>
      </b-col>
    </b-card>
  </b-overlay>
</template>

<script>
import { mapActions, mapState } from "vuex";
import axios from "@/axios";
import { sleep } from "@/utils/utils.js";

export default {
  data() {
    return {
      isLoading: false,
      fields: [
        { key: "itemModel", label: "Model" },
        { key: "price", label: "Price" },
        { key: "quantity", label: "Quantity" },
        { key: "actions", label: "Actions" },
      ],
      items: [],
      priceMaster: [],
      name: "",
      selectItem: {},
      selectQuantity: 1,
      selectChannel: {},
      listChannel: [],
      itemChannel: [],
      totalPrice: 0,
    };
  },
  mounted() {
    this.isLoading = true;
    this.getItems({ status: "Active" });

    //temporary get api distchan from axios
    Promise.all([
      axios.get("sales-and-purchase/drcc/pcode-distchan"),
      axios.get("sales-and-purchase/drcc/multi-price")
    ])
    .then(([channelResponse, priceResponse]) => {
      this.listChannel = channelResponse.data.data;
      this.priceMaster = priceResponse.data.data;
      this.isLoading = false;
    })
    .catch((e) => {
      console.log(e);
      this.isLoading = false;
    });
  },
  methods: {
    ...mapActions({
      getItems: "item/getItems",
      saveBundle: "item/addItemBundle",
    }),
    onSelectChannel(){
      const catalog = this.itemCatalogue;
      console.info('priceMaster',this.priceMaster)
      const channelItems = this.priceMaster.filter((x) => {
        return x.channel._id === this.selectChannel._id;
      });
      const result = [];
      this.items=[]
      console.info(this.selectChannel,channelItems)
      
      // Filter catalog items that match selected channel items
      catalog.forEach(catalogItem => {
        const matchingChannelItem = channelItems.find(c => c.item._id === catalogItem.value._id);
        if (matchingChannelItem) {
          // Add to result and update price in one step
          const item = { ...catalogItem };
          item.value.price = matchingChannelItem.price.$numberDecimal;
          result.push(item);
        }
      });
      this.itemChannel = result;
    },
    addItemBundle() {
      let { itemModel, _id, price } = this.selectItem;
      let quantity = this.selectQuantity;

      var check = this.items.find((x) => {
        return x.itemModel === itemModel;
      });
      if (check != undefined) {
        this.$bvToast.toast(`Item Already Exists`, {
          title: "Failed",
          variant: "danger",
          solid: true,
        });
      } else {
        this.items.push({
          itemModel,
          id: _id,
          price: price,
          quantity: quantity,
        });
      }

      this.selectItem = {};
      this.selectQuantity = 1;
      this.totalPrice += parseFloat(price) * quantity;
    },
    removeItem(row) {
      let { itemModel, price, quantity } = row.item;

      let prevLength = this.items.length;

      this.items = this.items.filter((i) => {
        return !(i.itemModel === itemModel);
      });

      let nowLength = this.items.length;

      if (nowLength < prevLength) {
        this.totalPrice -= parseFloat(price) * quantity;
        // console.log(this.totalPrice)
      }
    },
    doSaveBundle() {
      this.isLoading = true;
      let { name, selectChannel, totalPrice, items } = this;
      let channel = selectChannel._id;

      if (items.length < 0) {
        this.$bvToast.toast(`Cannot Add Bundle With Empty Item`, {
          title: "Failed",
          variant: "danger",
          solid: true,
        });
        this.isLoading = false;
        return;
      }

      if (name.length === 0) {
        this.$bvToast.toast(`Cannot Add Bundle With Empty Name`, {
          title: "Failed",
          variant: "danger",
          solid: true,
        });
        this.isLoading = false;
        return;
      }

      this.saveBundle({ name, channel, totalPrice, items })
        .then(async (data) => {
          this.$bvToast.toast(
            `You have successfully created a set name ${name}`,
            {
              title: "Success",
              variant: "success",
              solid: true,
            }
          );
          await sleep(1000);
          this.$router.push({ name: "bundles" });
        })
        .catch((e) => {
          console.log(e);
          this.isLoading = false;
          this.$bvToast.toast(
            `You have failed to create a set name ${this.name}`,
            {
              title: "Failed",
              variant: "danger",
              solid: true,
            }
          );
        });
    },
  },
  computed: {
    ...mapState({
      itemCatalogue: (state) => {
        return state.item.items.map((i) => {
          return Object.assign({
            value: i,
            text: `${i.description}(${i.itemModel})`,
          });
        });
      },
    }),
  },
};
</script>
